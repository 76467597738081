<template>
  <div class="home">
    <div class="header_top">
      <handleback></handleback>
      <div>充值</div>
      <div>兑换说明</div>
    </div>
    <div class="tab space_around">
      <!-- <div class="center btn" :class="index == 0?'active':''" @click="handleindex(0);playAudio()">
        <svg
          t="1708494904533"
          class="icon"
          viewBox="0 0 1228 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4039"
        >
          <path
            d="M530.8928 703.1296a41.472 41.472 0 0 1-35.7376-19.8144l-2.7136-5.5808L278.272 394.752a18.7392 18.7392 0 0 1-2.048-8.1408 19.968 19.968 0 0 1 20.48-19.3536c4.608 0 8.8576 1.4336 12.288 3.84l234.3936 139.9296a64.4096 64.4096 0 0 0 54.528 5.9392L1116.2624 204.8C1004.9536 80.896 821.76 0 614.4 0 275.0464 0 0 216.576 0 483.6352c0 145.7152 82.7392 276.8896 212.2752 365.5168a38.1952 38.1952 0 0 1 17.2032 31.488 44.4928 44.4928 0 0 1-2.1504 12.3904l-27.6992 97.4848c-1.3312 4.608-3.328 9.3696-3.328 14.1312 0 10.752 9.216 19.3536 20.48 19.3536 4.4032 0 8.0384-1.536 11.776-3.584l134.5536-73.3184c10.1376-5.5296 20.7872-8.96 32.6144-8.96 6.2976 0 12.288 0.9216 18.0736 2.5088 62.72 17.0496 130.4576 26.5728 200.5504 26.5728C953.7024 967.168 1228.8 750.592 1228.8 483.6352c0-80.9472-25.4464-157.1328-70.0416-224.1024l-604.9792 436.992-4.4544 2.4064a42.1376 42.1376 0 0 1-18.432 4.1984z"
            fill="#727477"
            p-id="4040"
          />
        </svg> 微信
      </div> -->
      <!-- <div
        class="center btn"
        :class="index == 1 ? 'active' : ''"
        @click="
          handleindex(1);
          playAudio();
        "
      >
        <svg
          t="1708494611211"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3334"
        >
          <path
            d="M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z"
            fill="#727477"
            p-id="3335"
          />
        </svg>
        支付宝
      </div> -->

      <div
        class="center btn"
        :class="index == 1 ? 'active' : ''"
        @click="
          handleindex(1);
          playAudio();
        "
      >
        <svg
          t="1708494611211"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3334"
        >
          <path
            d="M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z"
            fill="#727477"
            p-id="3335"
          />
        </svg>
        饰品充值
      </div>

      <div
        class="center btn"
        :class="index == 2 ? 'active' : ''"
        @click="
          handleindex(2);
          playAudio();
        "
      >
        <svg
          t="1708494904533"
          class="icon"
          viewBox="0 0 1228 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4039"
        >
          <path
            d="M530.8928 703.1296a41.472 41.472 0 0 1-35.7376-19.8144l-2.7136-5.5808L278.272 394.752a18.7392 18.7392 0 0 1-2.048-8.1408 19.968 19.968 0 0 1 20.48-19.3536c4.608 0 8.8576 1.4336 12.288 3.84l234.3936 139.9296a64.4096 64.4096 0 0 0 54.528 5.9392L1116.2624 204.8C1004.9536 80.896 821.76 0 614.4 0 275.0464 0 0 216.576 0 483.6352c0 145.7152 82.7392 276.8896 212.2752 365.5168a38.1952 38.1952 0 0 1 17.2032 31.488 44.4928 44.4928 0 0 1-2.1504 12.3904l-27.6992 97.4848c-1.3312 4.608-3.328 9.3696-3.328 14.1312 0 10.752 9.216 19.3536 20.48 19.3536 4.4032 0 8.0384-1.536 11.776-3.584l134.5536-73.3184c10.1376-5.5296 20.7872-8.96 32.6144-8.96 6.2976 0 12.288 0.9216 18.0736 2.5088 62.72 17.0496 130.4576 26.5728 200.5504 26.5728C953.7024 967.168 1228.8 750.592 1228.8 483.6352c0-80.9472-25.4464-157.1328-70.0416-224.1024l-604.9792 436.992-4.4544 2.4064a42.1376 42.1376 0 0 1-18.432 4.1984z"
            fill="#727477"
            p-id="4040"
          />
        </svg>
        微信
      </div>
      <div
        class="center btn"
        :class="index == 3 ? 'active' : ''"
        @click="
          handleindex(3);
          playAudio();
        "
      >
        <svg
          t="1708494940773"
          class="icon"
          viewBox="0 0 1228 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4192"
        >
          <path
            d="M83.33333333 305.675v507.69c0 76.365 69.3 138.465 154.575 138.465h746.01c85.185 0 154.53-62.1 154.53-138.465V305.675H83.33333333z m1055.115-80.145v-23.85c0-76.365-69.345-138.51-154.53-138.51H237.90833333C152.63333333 63.17 83.33333333 125.27 83.33333333 201.68v23.85h1055.115z m-264.87 337.185A157.635 157.635 0 0 1 1073.33333333 714.5a157.5 157.5 0 1 1-296.37-74.385l-120.6-135.675-1.035-91.215 85.41 1.98 1.305 67.995 61.92 3.24-2.07 66.825 63.225 0.45 8.46 9zM915.83333333 782a67.5 67.5 0 1 0 0-135 67.5 67.5 0 0 0 0 135z"
            fill="#727477"
            p-id="4193"
          /></svg
        >卡密
      </div>
      <div class="slider" ref="slider" :style="{ left: left + 'px' }"></div>
    </div>
    <div class="grade1 header" :class="{'grade3':index == 2}" v-if="index == 1 || index == 2">
      <div
        v-for="(item, listIndex) in list"
        :key="listIndex"
        class="card center"
        @click="
          handlec(item);
          playAudio();
        "
        :class="item.isselect ? 'actives' : ''"
      >
        <template v-if="index == 1">
          <div class="skins">
            <img :src="item.picture | fullPath" alt="" />
          </div>
          <div class="info">
            <div class="title">{{ item.name }}</div>
            <div class="price">
              <integral class="integral"></integral>{{ item.price }}
            </div>
          </div>
        </template>
        <template v-if="index == 2">
          <div class="wechat-price">
            {{ item.price }}
          </div>
        </template>
      </div>
    </div>

    <div class="main space_between" v-if="index == 1 || index == 2">
      <div>支付金额</div>
      <div>{{ money }} 元</div>
    </div>
    <div v-if="index == 3" class="kami">
      <input
        type="text"
        class="kami_input"
        v-model="kami"
        placeholder="请输入卡密"
      />
    </div>
    <div class="main_center_bottom">
      <div
        :class="select ? 'select' : ''"
        @click="
          handleAgree();
          playAudio();
        "
      ></div>
      <div>
        我 已 满 18 岁，并 阅 读 和 同 意《 用 户 协 议 》、《 隐 私 政 策
        》和《 反 洗 钱 协 议 》，承 诺 理 性 消 费。
      </div>
    </div>
    <div
      class="bottom center"
      v-if="index == 1"
      :class="kami && select ? 'activess' : ''"
      @click="
        skinsBuyBtn();
        playAudio();
      "
    >
      支付
    </div>
    <div
      v-if="index == 2 || index == 3"
      class="bottom center"
      :class="ready && select ? 'activess' : ''"
      @click="
        handlePay();
        playAudio();
      "
    >
      支付
    </div>
    <!-- <div class="recharge" v-if="index == 3">
      <div class="title">京东卡密充值找客服进行核销，卡密充值更优惠大额无限制</div>
      <img class="recharge_img"  src="@/assets/recharge.jpg" alt="" />
    </div> -->
    <div class="footer">
      <div
        v-if="isRealCheck == '0'"
        class="footer_x"
        @click="
          Realnameauthentication = true;
          playAudio();
        "
      >
        您还未实名认证，点击立即进行实名认证
        <div class="footer_x_u"></div>
      </div>
      <div class="footer_d" v-if="isRealCheck == '1'">
        已实名认证
        <div class="footer_d_u"></div>
      </div>
    </div>
    <div class="out_II" v-if="Realnameauthentication">
      <div
        class="out_II_I column animate__animated animate__slideInDown"
        ref="nameel"
      >
        <div class="out_II_I_I">实名认证</div>
        <div class="out_II_I_II">
          <input
            type="text"
            v-model="Exampleobj.realName"
            name
            id
            placeholder="请输入您的真实姓名"
          />
        </div>
        <div class="out_II_I_III">
          <input
            type="text"
            v-model="Exampleobj.idNum"
            placeholder="请输入您的身份证号码"
          />
        </div>
        <div class="out_II_I_IIII space_around">
          <div
            class="btn_c center btn_click"
            @click="
              handleconfirmnamequxiao();
              playAudio();
            "
          >
            取消
          </div>
          <div
            class="btn_s center btn_click"
            @click="
              handleconfirmname();
              playAudio();
            "
          >
            <div v-if="onsubmit">确定</div>
            <div v-else>
              <i class="el-icon-loading"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="out_II" v-if="wxorder">
      <div
        class="out_II_I columns animate__animated animate__slideInDown"
        ref="nameel"
      >
        <div class="out_II_I_I">支付宝</div>
        <!-- <div ref="qrCode" class="pay" v-loading="loading"></div> -->
        <div style="width: 100%; height: 200px"></div>
        <div id="code">
          <canvas id="canvas"></canvas>
        </div>
        <div>订单生成后,请用支付宝描二维码进行支付</div>
        <div class="out_II_I_IIII space_around">
          <!-- <div
            class="btn_c center btn_click"
            @click="
              handleconfirmnamequxiao();
              playAudio();
            "
          >
            确定
          </div> -->
          <div
            class="btn_c center btn_click"
            @click="
              handleconfirmnamequxiao();
              playAudio();
            "
          >
            取消
          </div>
        </div>
      </div>
    </div>
    <div class="out_II" v-if="skinsBuy">
      <div
        class="out_II_I columns animate__animated animate__slideInDown"
        ref="nameel"
      >
        <div class="out_II_I_I">充值结果</div>
        <div>交易链接：{{ obj.transactionLink }}</div>
        <div class="tips">
          *请复制链接完成交易，交易完成之后复制订单号找客服进行兑换。
        </div>
        <div class="out_II_I_IIII space_around">
          <div
            class="btn_c center btn_click"
            @click="
              handleconfirmnamequxiao();
              playAudio();
            "
          >
            关闭
          </div>
          <div
            class="btn_s center btn_click"
            @click="
              copy();
              playAudio();
            "
          >
            复制交易链接
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import {
  jiuJiaPay,
  getRechargelist,
  realNameAuthentication,
  kamiPay,
  getUserInfo,
  Recgargewx,
  ApiAddTrans,
  ApiAdddgfht,
} from "@/api/index";
export default {
  data() {
    return {
      Exampleobj: {
        idNum: "",
        realName: "",
        password: "",
        password1: "",
      },
      loading: false,
      wxorder: false,
      skinsBuy: false,
      Realnameauthentication: false,
      onsubmit: true,
      isRealCheck: false,
      money: 0,
      select: false,
      left: 1,
      ready: false,
      index: 1,
      list: [],
      kami: "",
      obj: {
        goodsPrice: null,
        goodsId: null,
        goodsNum: 1,
      },
    };
  },

  mounted() {
    this.getrechangeList();
    getUserInfo().then((res) => {
      if (res.data.code == 200) {
        this.$store.commit("USER_INFO", res.data.data);
      }
    });
  },
  methods: {
    copy() {
      const content = this.obj.transactionLink;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(content);
        this.$message({
          message: "复制成功",
          type: "success",
          customClass: "log_success",
        });
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = content;
        // 将文本框设置为不可见，避免影响用户体验
        textArea.style.position = "fixed";
        textArea.style.top = "-9999px";
        document.body.appendChild(textArea);

        // 选择文本并复制
        textArea.focus();
        textArea.select();
        const isSuccess = document.execCommand("copy");

        // 移除临时的文本框
        document.body.removeChild(textArea);
        isSuccess &&
          this.$message({
            message: "复制成功",
            type: "success",
            customClass: "log_success",
          });
      }
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    handlePay() {
      if (!this.kami && !this.ready) {
        return false;
      }
      if (this.isRealCheck == "0") {
        this.$message({
          message: "请先进行实名认证",
          type: "warning",
          customClass: "log_warning",
        });
        return false;
      }
      this.loading = true;

      if (this.select) {
        if (this.index == 0) {
          const obj = {
            coinItemId: this.obj.goodsId,
            coinItemNum: this.obj.goodsNum,
            coinItemAmount: this.obj.goodsPrice,
          };
          this.wxorder = true;
          Recgargewx(obj).then((res) => {
            // window.open(res.data.data.url);
            // window.location.href = `weixin://${res.data.data.url}`;
            if (res.data.code == 200) {
              this.loading = false;
              const url = res.data.data.url;
              QRCode.toDataURL(url, { type: "image/png" }, (err, url) => {
                console.log(err, url, "转换结果");
                if (err) throw err;
                this.$refs.qrCode.innerHTML = `<img src="${url}"  alt="QR Code" width='100%'>`;
              });
            } else {
              this.wxorder = false;
            }
          });
        } else if (this.index == 4) {
          // console.log(123);
          if (this.obj.goodsId == null) {
            this.$message({
              message: "请选择金额，再进行支付",
              type: "warning",
              customClass: "log_warning",
            });
          } else {
            jiuJiaPay(this.obj).then((res) => {
              console.log("九价", res);
              window.open(res.data.data);
            });
          }
        } else if (this.index == 3) {
          kamiPay({ password: this.kami }).then((res) => {
            if (res.data.code == 200) {
              this.kami = "";
              getUserInfo().then((res) => {
                if (res.data.code == 200) {
                  this.$store.commit("USER_INFO", res.data.data);
                }
              });
              this.$message({
                type: "success",
                message: res.data.msg,
                customClass: "log_success",
              });
            } else {
              console.log(res);
              this.$message({
                type: "error",
                message: res.data.msg,
                customClass: "log_success",
              });
            }
          });
        } else if (this.index == 2) {
          ApiAddTrans(this.obj).then((res) => {
            console.log("ApiAddTrans", res);
            console.log("res.data.data.payUrl;", res.data.data.payUrl);

            // window.location.href = res.data.data.payUrl;
          }); // this.$message({
          //   type: "success",
          //   message: "敬请期待",
          //   customClass: "log_success"
          // });
        } else if (this.index == 1) {
          // window.location.href =`weixin:// `
          console.log(1, this.obj);
          // mayi(this.obj).then((res) => {
          const data = { ...this.obj };
          console.log("国富汇通支付宝", data);
          ApiAdddgfht(data).then((res) => {
            if (res.data.code === 500) {
              this.$message({
                message: res.data.msg,
                type: "warning",
                customClass: "log_warning",
              });
              return;
            }
            console.log("国富汇通支付宝", res, res.data.data.payurl);
            const url = res.data.data.payurl
              ? res.data.data.payurl
              : res.data.data.qrcode;
            // console.log(url,"lianjie")
            // window.location.href = res.data.data.payUrl
            this.wxorder = true;
            setTimeout(() => {
              console.log(1111);

              var canvas = document.getElementById("canvas"); //获取到canvas

              var code = document.getElementById("code"); //获取到code容器
              console.log(canvas, code, "测试222", url);
              QRCode.toCanvas(canvas, url, (error) => {
                if (error) console.error(error);

                console.log("success!");
              });

              var image = new Image(); //实例一个img

              image.src = canvas.toDataURL("image/png"); //转换成base64格式路径的png图片

              image.style.width = "100%"; //设置样式

              code.appendChild(image); //添加到code 容器中

              canvas.style.display = "none"; //隐藏掉canvas
            }, 200);
          });
        }
      } else {
        this.$message({
          message: "请先同意《用户协议》，《隐私政策》等内容",
          type: "warning",
          customClass: "log_warning",
        });
      }
    },
    skinsBuyBtn() {
      this.skinsBuy = true;
      return;
    },
    getrechangeList() {
      getUserInfo().then((res) => {
        this.isRealCheck = res.data.data.isRealCheck;
      });
      getRechargelist().then((res) => {
        this.list = res.data.data;
      });
    },
    handleconfirmname() {
      this.onsubmit = false;
      realNameAuthentication(this.Exampleobj).then((res) => {
        if (res.data.code === 200) {
          const element = this.$refs.nameel;
          element.className += " animate__slideOutUp";
          this.transactionLink = "";
          setTimeout(() => {
            this.onsubmit = true;
            this.$message({
              message: "实名认证成功",
              type: "success",
              customClass: "log_success",
            });
          }, 500);
        } else {
          this.onsubmit = true;
        }
      });
    },
    handleconfirmnamequxiao() {
      const element = this.$refs.nameel;
      element.className += " animate__slideOutDown";
      this.Exampleobj = {
        idNum: "",
        realName: "",
        password: "",
        password1: "",
      };
      setTimeout(() => {
        this.Realnameauthentication = false;
        this.changepassword = false;
        this.issign = false;
        this.wxorder = false;
        this.skinsBuy = false;
        this.getUserInfo().then((res) => {
          if (res.data.code == 200) {
            this.$store.commit("USER_INFO", res.data.data);
          }
        });
      }, 400);
    },
    handlec(res) {
      this.obj.goodsId = res.id;
      this.obj.goodsPrice = res.price;
      this.obj.transactionLink = res.transactionLink;
      this.money = res.price;
      this.list.forEach((e) => {
        e.isselect = false;
      });
      res.isselect = true;
      this.ready = true;
    },
    handleAgree() {
      this.select = !this.select;
    },
    handleindex(res) {
      this.index = res;
      const element = this.$refs.slider;
      // if (res == 3) {
      //   this.left = 1 * element.offsetWidth + 3;
      // } else {
      this.left = (res - 1) * element.offsetWidth + 3;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  margin: 0 auto;

  @media (max-width: 550px) {
    width: 140px;
  }
}

.home {
  margin: 0 auto;
  width: 95%;

  .header_top {
    width: 100%;
    height: 56px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
    }

    div:last-child {
      color: #fff;
      font-size: 12px;
      text-decoration: underline;
      line-height: 1.5;
      opacity: 0;
    }
  }
}

.tab {
  // width: 399px;
  width: 240px;
  height: 42px;
  background: #323436;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  margin: 0 auto;
  transition: all 0.3s linear;

  color: #727477;
  font-size: 14px;
  position: relative;

  @media (max-width: 550px) {
    // width: 214px;
    width: 240px;
    height: 30px;
  }
}

.btn {
  width: 98px;
  height: 35px;
  position: relative;
  z-index: 2;

  // border: 1px solid red;
  @media (max-width: 550px) {
    width: 75px;
    height: 25px;
    font-size: 10px;
  }

  .icon {
    width: 28px;
    height: 28px;
    margin: 5px;

    @media (max-width: 550px) {
      width: 20px;
      height: 20px;
    }
  }
}

.slider {
  position: absolute;
  width: 80px;
  height: 35px;
  background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
  border-radius: 70px;
  transition: left 0.2s ease-in-out;

  @media (max-width: 550px) {
    // width: 70px;
    height: 25px;
  }
}

.active {
  color: #fff;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: #fff;
      transition: all 0.2s ease-in-out;
    }
  }
}

.header {
  margin-top: 29px;
}

.card {
  width: 100%;
  height: 100px;
  background: linear-gradient(
    90deg,
    rgba(58, 151, 228, 0.3) 0%,
    rgba(37, 244, 132, 0.3) 100%
  );
  border-radius: 14px 14px 14px 14px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  font-weight: 700;
  .skins {
    width: auto;
    height: auto;
    max-height: 80px;
    min-width: 30%;
    img {
      width: auto;
      height: auto;
      max-height: 80px;
      min-width: 30%;
    }
  }
  .info {
    text-align: left;
    width: 50%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    .title {
      font-size: 18px;
      color: #fff;
    }
    .price {
      font-size: 22px;
      color: #d9db7d;
      display: flex;
      align-items: center;
    }
  }
  // padding-top: 20px;
  @media (max-width: 550px) {
    // padding-top: 20px;
    width: 100%;
    height: 100px;
  }
}

.prz {
  width: 98px;
  height: 35px;
  background: linear-gradient(90deg, #c23dd4 0%, #6f4fff 100%);
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 14px 14px 14px 14px;
  color: #fff;
  font-size: 14px;

  @media (max-width: 550px) {
    width: 70px;
    height: 25px;
    font-size: 10px;
  }

  .icon {
    width: 21px;
    height: 21px;
    margin: 0;

    @media (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }
}

.main_center_bottom {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;

  .select {
    background: #3a97e4 !important;
    border: 2px solid #3a97e4 !important;
  }

  div:first-child {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #727477;
    box-sizing: border-box;
    margin-top: 5px;

    @media (max-width: 550px) {
      margin-top: 3px;
      width: 8px;
      height: 8px;
      border: 1px solid #727477;
    }
  }

  div:last-child {
    width: 95%;
    color: #ffffff;
    font-size: 16.8px;
    text-align: left;
    word-spacing: -2px;

    @media (max-width: 550px) {
      font-size: 12px;
    }
  }
}

.main {
  width: 100%;
  height: 56px;
  background: #585a60;
  border-radius: 35px 35px 35px 35px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 29px;

  font-size: 21px;
  padding: 0 66px 0 33px;

  @media (max-width: 550px) {
    margin-top: 20px;

    font-size: 15px;
    height: 40px;
    padding: 0 47px 0 24px;
  }
}

.bottom {
  width: 100%;
  height: 56px;
  background: linear-gradient(
    360deg,
    rgba(114, 116, 119, 0.2) 0%,
    rgba(114, 116, 119, 0.1) 100%
  );
  opacity: 1;
  border: 1px solid #727477;
  margin: 0 auto;
  margin-top: 14px;

  font-size: 25px;

  @media (max-width: 550px) {
    font-size: 18px;
    height: 40px;
  }
}

.actives {
  color: #fff;

  background: linear-gradient(
    -90deg,
    rgba(58, 151, 228, 0.3) 0%,
    rgba(37, 244, 132, 0.3) 100%
  );
  opacity: 1;
  border: 1px solid;
  // border-image: linear-gradient(
  //     270deg,
  //     rgba(37.00000159442425, 244.0000006556511, 132.0000073313713, 1),
  //     rgba(58.00000034272671, 151.00000619888306, 228.0000016093254, 1)
  //   )
  //   1 1;
  border-left-color: rgba(37, 244, 132, 1);
  border-right-color: rgba(58, 151, 228, 1);
  border-top-color: rgba(37, 244, 132, 1);
  border-bottom-color: rgba(58, 151, 228, 1);
}

.activess {
  background: linear-gradient(
    360deg,
    rgba(37, 244, 132, 0.2) 0%,
    rgba(37, 244, 132, 0.1) 100%
  );
  opacity: 1;
  border: 1px solid #25f484;
  transition: all 0.3s ease-in-out;
}

.footer {
  margin-top: 40px;
  font-size: 18px;

  @media (max-width: 550px) {
    font-size: 13px;
  }
}

.footer_x {
  width: 329px;
  margin: 0 auto;
  color: #25f484;

  @media (max-width: 550px) {
    width: 237px;
  }

  .footer_x_u {
    width: 100%;
    height: 3px;
    background-color: #25f484;
    border-radius: 50px;
  }
}

.footer_d {
  margin: 0 auto;

  width: 95px;
  color: #727477;

  @media (max-width: 550px) {
    width: 71px;
  }

  .footer_d_u {
    width: 100%;
    height: 3px;
    background-color: #727477;
    border-radius: 50px;
  }
}

.out_II {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;

  @media (max-width: 550px) {
    width: 100%;
  }

  .out_II_I {
    width: 95%;
    margin: 0 auto;
    position: relative;

    height: 335px;
    background: #101115;
    border-radius: 23px 23px 23px 23px;
    margin-top: 211px;
    overflow: hidden;
    justify-content: space-around;

    @media (max-width: 550px) {
      margin-top: 150px;
    }

    #code {
      position: absolute;
      z-index: 99;
    }

    .out_II_I_I {
      font-size: 28px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 20px;
      }
    }

    .out_II_I_II {
      width: 89%;
      height: 56px;

      @media (max-width: 550px) {
        height: 40px;
      }

      input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #323436;
        border-radius: 35px 35px 35px 35px;
        border: none;
        outline: none;
        font-size: 21px;
        color: #fff;
        text-align: center;
      }

      input::-webkit-input-placeholder {
        text-align: center;
        font-size: 21px;
        color: #fff;

        @media (max-width: 550px) {
          font-size: 15px;
        }
      }
    }

    .out_II_I_III {
      width: 89%;
      height: 56px;

      input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #323436;
        border-radius: 20px;
        border: none;
        outline: none;
        font-size: 21px;
        color: #fff;
        text-align: center;

        @media (max-width: 550px) {
          height: 40px;
        }
      }

      input::-webkit-input-placeholder {
        text-align: center;
        font-size: 21px;
        color: #fff;

        @media (max-width: 550px) {
          font-size: 15px;
        }
      }
    }

    .out_II_I_IIII {
      width: 89%;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      font-size: 21px;

      .btn_c {
        width: 196px;
        height: 42px;

        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;

        @media (max-width: 550px) {
          width: 140px;
          height: 30px;

          font-size: 15px;
        }
      }

      .btn_s {
        width: 196px;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;

        @media (max-width: 550px) {
          width: 140px;
          height: 30px;

          font-size: 15px;
        }
      }
    }
  }

  .out_II_I::before {
    content: "";
    /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -222deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}

.kami {
  margin-top: 14px;
  width: 100%;
  height: 56px;
  background: #101115;
  border-radius: 20px;

  overflow: hidden;

  @media (max-width: 550px) {
    height: 40px;
  }
}

.kami_input {
  width: 100%;
  font-size: 21px;

  height: 100%;
  background-color: #585a60;
  border: none;
  color: #fff;
  text-align: center;
  outline: none;

  @media (max-width: 550px) {
    height: 40px;
    font-size: 15px;
  }
}

.kami_input::-webkit-input-placeholder {
  color: #fff;
}
.recharge {
  .title {
    color: #e83e39;
    margin-top: 20px;
  }
  .recharge_img {
    width: 200px;
    height: 200px;
    margin: 30px auto;
  }
}
.tips {
  color: #e83e39;
  padding: 0 20px;
  text-align: left;
}
.wechat-price{

}
</style>
